* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound {
    width: 100%;
  line-height: 1.4;
  text-align: center;
  justify-content: center;
  margin-top: 30px;
}

.notfound .notfound-404 {


  display: flex;
  justify-content: center;
  align-content: center;
}

.notfound .notfound-404 h1 {
  font-family: 'Montserrat', sans-serif;

  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 200px;
  font-weight: 900;

  color: #32BEE1;
  text-transform: uppercase;
  letter-spacing: -50px;}

.notfound .notfound-404 h1>span {
  text-shadow: -8px 0px 0px #fff;
}

.notfound .notfound-404 h3 {
  font-family: 'Cabin', sans-serif;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
  margin: 0px;
  letter-spacing: 3px;
  padding-left: 6px;
}

.notfound h2 {
  font-family: 'Cabin', sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  margin-top: 0px;
  margin-bottom: 25px;
}


