.material-categories-modal {
  width: 60vw;
  max-width: 1500px;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  position: absolute;
  border-radius: 5px;
}

.close-material-categories-modal-button {
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 999999 !important;
}

.material-categories-modal-content {
  margin: 0 !important;
  padding: 10px !important;
  width: calc(100%) !important;
}