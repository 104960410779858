.edit-process-modal-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-process-modal {
  width: 85vw;
  max-width: 1500px;
  height: 90vh;
  max-height: 1500px;
}

.close-edit-process-modal-button {
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 999999 !important;
}

.edit-process-modal-paper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
}

.edit-process-modal-content {
  position: absolute;
  margin: 0 !important;
  padding: 10px !important;
  width: calc(100%) !important;
  max-height: calc(100%) !important;
  overflow: auto;
}
