.create-shipment-modal-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-shipment-modal {
  width: 80vw;
  max-width: 1250px;
  height: 85vh;
  max-height: 1250px;
}

.close-create-shipment-modal-button {
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 99999 !important;
}

.create-shipment-modal-paper {
  width: 80vw;
  max-width: 1250px;
  height: 85vh;
  max-height: 1250px;
  position: relative;
  overflow-y: scroll;
}

.create-shipment-modal-content {
  position: absolute;
  margin: 0 !important;
  padding: 20px !important;
  width: 80vw;
  max-width: 1250px;
  height: 85vh;
  max-height: 1250px;
  overflow: hidden;
}

.swal2-container {
  z-index: 999999 !important;
}
